.timeChart {
  position: relative;
  width: 100%;
  display: block; }

.timeChart .container {
  position: absolute;
  width: calc(100% - 6rem);
  top: 0px;
  left: 4rem;
  display: block; }

.timeChart > .entry {
  position: relative;
  height: 1.5rem;
  border-bottom: 1px solid #bfa948; }

.timeChart > .container > .content {
  background-color: #FFF;
  border: 1px solid #bfa948;
  min-height: 1.5rem;
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 0;
  text-align: left; }

.timeChart > .container > .content.selected {
  background-color: #c5ffc5;
  border: 1px solid #009200; }

.timeChart .startTime {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  background: #FFF; }

.timeChart .endTime {
  position: absolute;
  right: 0px;
  bottom: 0px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  background: #FFF; }
