.loading {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10000; }

.loading-small {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10000; }

.loading-knob {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  text-align: center;
  width: 30rem;
  height: 14rem;
  margin-top: -7rem;
  margin-left: -15rem; }

.loading-tiny .loading-knob {
  width: 20rem;
  height: 6rem;
  margin-top: -3rem;
  margin-left: -10rem; }

.loading-knob-bg {
  font-size: 8rem;
  color: #cccccc;
  text-shadow: #000 1px 0 10px;
  line-height: 10rem; }

.loading-tiny .loading-knob-bg {
  font-size: 4rem;
  line-height: 5rem; }

.loading-knob-fg {
  font-size: 7rem;
  color: white;
  text-shadow: #000 1px 0 10px;
  line-height: 10rem; }

.loading-tiny .loading-knob-fg {
  font-size: 3rem;
  line-height: 5rem; }

.loading-knob-text {
  font-size: 4rem;
  color: white;
  line-height: 4rem;
  text-shadow: #000 1px 0 10px;
  padding-top: 10rem; }

.loading-tiny .loading-knob-text {
  font-size: 2rem;
  line-height: 3rem;
  padding-top: 4rem; }

.fa-spin-ease {
  animation-direction: normal !important;
  animation-timing-function: ease-in-out !important; }

.fa-spin-ease-other {
  animation-direction: reverse !important;
  animation-timing-function: linear !important; }
