.row {
  margin: 0.5rem 0; }

.row:after {
  content: "";
  display: table;
  clear: both; }

.row-flex {
  display: flex;
  align-items: flex-start; }

.row-micro {
  margin: 0; }
