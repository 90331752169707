textarea {
  font-size: 1rem;
  padding: 0.5rem 0.5rem;
  border: 1px solid #cfcfcf;
  display: block;
  margin: auto;
  box-sizing: border-box;
  width: 100%;
  line-height: 1.5rem;
  font-family: 'Ubuntu', sans-serif; }

textarea:focus {
  outline: 1px #ccbb6e dashed; }

textarea::placeholder {
  color: #BBB; }
