.smartInput {
  position: relative; }

.smartInput .smartInputResults {
  display: block;
  background: #FFF;
  left: 0;
  outline: 1px #ccbb6e dashed;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1); }

.smartInput .smartInputResultsWrapper {
  position: absolute;
  z-index: 1; }

.smartInput .smartInputResult {
  display: block;
  cursor: pointer; }

.smartInput .smartInputResult:hover, .smartInput .smartInputResult.selected {
  background: #e0e0e0; }

.smartInputIndicator {
  font-size: 1rem;
  border-left: 1px solid #cfcfcf;
  padding: 0.5rem 0.5rem;
  display: block;
  box-sizing: border-box;
  line-height: 1.5rem;
  position: absolute;
  right: 0px;
  top: 0px; }
