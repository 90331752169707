.login {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.login-panel {
  display: block;
  margin: auto;
  padding-top: 4rem; }

.login-panel > .container {
  position: relative;
  width: 30rem; }

.login-header {
  color: #ab3428;
  font-size: 4rem;
  text-align: center;
  line-height: 4rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #CCC; }

.login-body {
  padding: 1.5rem 0;
  border-bottom: 1px solid #CCC; }

.login-footer {
  padding-top: 1.5rem;
  text-align: right; }

.login-subtext {
  color: #666;
  font-size: 1rem;
  line-height: 1rem; }

.login-bg-cogs {
  position: absolute;
  left: -5.5rem;
  bottom: -5.5rem;
  color: rgba(0, 0, 0, 0.5);
  font-size: 15rem;
  z-index: -10; }

.login-bg-cogs i {
  animation-duration: 22s !important; }
