input, .input-disabled, .input-overlay, .input-container .backtext, label, .smartInput .smartInputResult {
  font-size: 1rem;
  border: 1px solid transparent;
  padding: 0.5rem 0.5rem;
  display: block;
  margin: auto;
  box-sizing: border-box;
  width: 100%;
  line-height: 1.5rem;
  position: relative;
  background: none;
  font-family: "Ubuntu", sans-serif; }

input, .input-disabled {
  border: 1px solid #cfcfcf; }

input:focus {
  outline: 1px #ccbb6e dashed; }

input::placeholder {
  color: #BBB; }

.input-disabled {
  border: 1px solid rgba(0, 0, 0, 0); }

.input-overlay {
  border: 1px transparent #cfcfcf;
  color: #BBB; }

.input-overlay .red {
  color: #ab3428; }

input.border-red, .input-disabled.border-red {
  border: 1px solid #ab3428; }

input.monospace, .input-container .monospace, .DayPickerInput input {
  font-family: "Ubuntu Mono", monospace;
  font-weight: bold; }

.input-container {
  position: relative; }

.input-container .backtext {
  color: #BBB;
  white-space: pre-wrap;
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: 0px;
  left: 0px; }

.datepicker-container {
  position: relative; }

.datepicker-button {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 0.5rem 0.5rem;
  line-height: 1.5rem;
  border-left: 1px solid #cfcfcf;
  cursor: pointer; }

.datepicker-dropdown-container {
  top: 2.5rem;
  right: 0px;
  position: absolute;
  border: 1px solid #cfcfcf;
  padding: 0.5rem;
  background: white; }
