.container.margins {
  margin: 1rem; }

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 1rem; }

.container.fill {
  padding: 0; }

.container.panel {
  border: 1px solid #888;
  background-color: #FFF; }

.container.halfSpace {
  margin-top: 0.5rem; }

.container-vscrolled {
  overflow-y: auto; }

.container-vfull {
  max-height: calc(100vh - 8rem - 2px); }
