.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.col {
  margin-left: 1%;
  display: inline-block;
  min-height: 1px; }

.col:first-child {
  margin-left: 0; }

.col-1 {
  width: 7.41667%; }

.col-2 {
  width: 15.83333%; }

.col-3 {
  width: 24.25%; }

.col-4 {
  width: 32.66667%; }

.col-5 {
  width: 41.08333%; }

.col-6 {
  width: 49.5%; }

.col-7 {
  width: 57.91667%; }

.col-8 {
  width: 66.33333%; }

.col-9 {
  width: 74.75%; }

.col-10 {
  width: 83.16667%; }

.col-11 {
  width: 91.58333%; }

.col-12 {
  width: 100%;
  margin-left: 0; }

.col-sticky {
  position: sticky;
  top: 1rem; }

.col-vscrolled {
  overflow-y: scroll; }

.col-vfull {
  max-height: calc(100vh - 6rem); }
