.treeView {
  padding-left: 0.5rem;
  line-height: 1.4rem; }

.treeView ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none; }

.treeView li {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  border-left: 1px solid #999;
  padding-left: 1rem;
  position: relative;
  user-select: none;
  font-size: 1.2rem; }

.treeView li > span {
  display: flex;
  border: 1px solid transparent;
  padding-left: 0.25rem; }

.treeView li > span > span {
  width: 100%;
  padding-left: 0.25rem; }

.treeView li .not-selected:hover {
  background: rgba(0, 105, 146, 0.2); }

.treeView li .selected {
  background: rgba(0, 105, 146, 0.4);
  color: #000; }

.treeView li:before {
  content: " ";
  position: absolute;
  left: 0px;
  top: 0.7rem;
  width: 1rem;
  border-bottom: 1px solid #999; }

.treeView li:last-child {
  border-left: 1px solid transparent; }

.treeView li:last-child:after {
  border-left: 1px solid #999;
  position: absolute;
  left: -1px;
  height: 0.7rem;
  top: 0;
  content: ""; }

.treeView .chevron {
  position: absolute;
  left: -0.6rem;
  top: 0.05rem;
  background-color: #FFF;
  z-index: 1;
  padding-right: 1px;
  font-size: 1.4rem; }
