.modal-wrapper {
  position: fixed;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  padding: 1rem; }

.modal-wrapper-bg {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  pointer-events: none;
  z-index: -1; }

.modal-body {
  overflow: hidden; }

.modal-close-button {
  position: absolute;
  right: .5rem;
  top: .5rem;
  font-size: 2rem;
  z-index: 1; }

.modal-close-button:hover {
  color: #BBB; }

.modal-small {
  width: 35rem; }

.modal-medium {
  width: 50rem; }

.modal-large {
  width: 75rem; }
