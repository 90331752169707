.table {
  border: 1px solid #bfa948;
  width: 100%; }

.table th {
  background: #ccbb6e;
  color: #FFF;
  padding: 0.5rem 0.5rem;
  border-bottom: 4px solid #bfa948;
  border-left: 1px solid #bfa948;
  white-space: nowrap; }

.table td {
  padding: 0.25rem 0.5rem;
  border-bottom: 1px solid #BBB;
  border-left: 1px solid #BBB;
  white-space: nowrap;
  min-height: 1rem; }

.table td.fill {
  padding: 0;
  position: relative; }

.table tr:last-child td {
  border-bottom: none; }

.table tr:hover td {
  background: rgba(0, 105, 146, 0.2); }

.table tr.selected td {
  background: rgba(0, 105, 146, 0.4); }

.table-column-maximize {
  width: 99%; }

.table .no-data-message {
  color: rgba(51, 51, 51, 0.4);
  text-align: center; }

.table-color-struct {
  background-color: rgba(255, 0, 0, 0.2); }

.table-color-punkt {
  background-color: rgba(0, 255, 0, 0.2);
  padding-left: 1rem !important; }

.table-color-praca {
  background-color: rgba(0, 0, 255, 0.2);
  padding-left: 1.5rem !important; }
