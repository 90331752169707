select {
  font-size: 1rem;
  padding: 0.5rem 3rem 0.5rem 0.5rem;
  border: 1px solid #cfcfcf;
  display: block;
  margin: auto;
  box-sizing: border-box;
  width: 100%;
  appearance: none;
  line-height: 1.5rem;
  height: 2.5rem; }

option {
  line-height: 1rem;
  padding: 0.5rem; }

.select-wrapper {
  position: relative;
  display: block; }

.select-wrapper.inline {
  display: inline-block; }

.select-wrapper:after {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  text-align: center;
  width: 1.25em;
  line-height: 1.5rem;
  height: 1.5rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  content: "\f078";
  font-size: 1rem;
  color: #333;
  right: 0;
  top: -1px;
  padding: 0.5rem;
  border-left: 1px solid #cfcfcf;
  position: absolute;
  pointer-events: none; }

select:focus {
  outline: 1px #ccbb6e dashed; }

select::placeholder {
  color: #BBB; }
