.week-graph {
  position: relative; }

.week-graph .weekGraphElement {
  display: block;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  line-height: 1.5rem;
  border-right: 1px solid #AAA;
  border-bottom: 1px solid #AAA;
  box-sizing: border-box;
  white-space: nowrap;
  font-size: 0.75rem;
  z-index: 1; }

.weekGraphElement.selected {
  box-shadow: inset 0 0 0px 2px #000000; }

.weekGraphElement.selN {
  box-shadow: inset 2px 2px 0px 1px #000; }

.weekGraphElement.planned-finished-notlate {
  background: repeating-linear-gradient(45deg, #78fa78, #78fa78 1rem, #fafa78 1rem, #fafa78 2rem) !important; }

.weekGraphElement.planned-finished-late {
  background: repeating-linear-gradient(45deg, #78fa78, #78fa78 1rem, #fab478 1rem, #fab478 2rem) !important; }

.weekGraphElement.planned-notlate {
  background-color: #c8c850 !important; }

.weekGraphElement.planned-late {
  background-color: #c85050 !important; }

.weekGraphElement.finished {
  background-color: #50c850 !important; }

.weekGraphElement.planned-lost {
  background-color: #464646 !important;
  color: #FFF; }

.weekGraphGridElement {
  border-right: 1px solid #AAA;
  z-index: 0 !important; }

.weekGraphHorzGridElement {
  border-bottom: 1px solid #AAA;
  z-index: 0 !important; }

.zCover {
  z-index: 1; }
