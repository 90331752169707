.text-primary {
  color: #ccbb6e; }

.text-secondary {
  color: #ab3428; }

.text-tetriary {
  color: #006992; }

.text-light {
  color: #888; }

.text-lighter {
  color: #BBB; }

.text-tall-line {
  line-height: 2rem; }

.text-danger {
  color: #ab3428; }

.text-red {
  color: #ab3428; }

.text-green {
  color: #009200; }
