::selection {
  background: rgba(0, 105, 146, 0.2); }

body {
  background-color: #F9F9F9;
  font-size: 100%;
  font-family: "Ubuntu", sans-serif;
  color: #333;
  padding-top: 3rem;
  overflow-y: hidden;
  line-height: 1.5rem; }

.font-small {
  font-size: 0.8rem; }

.font-normal {
  font-size: 0.8rem; }

.font-large {
  font-size: 0.8rem; }

.font-header {
  font-size: 0.8rem; }

sub {
  font-size: 80%;
  opacity: 0.8; }

p, li {
  font-size: 1rem; }

h1 {
  font-size: 2rem;
  margin-bottom: 1rem; }

h2 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem; }

a {
  color: #ab3428;
  text-decoration: none; }

b {
  font-weight: bold; }

.block {
  display: block; }

.relative {
  position: relative; }

.clickable {
  cursor: pointer;
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */ }

.nopadding {
  padding: 0; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

ul {
  margin: 0;
  padding: 0 0 0 2rem;
  list-style-type: none; }

li {
  margin: 1rem 0 0 0;
  padding: 0;
  list-style-type: disc;
  font-size: 1.2rem; }

.nowrap {
  white-space: nowrap; }

.leftPadding {
  padding-left: 1rem; }

.rightPadding {
  padding-right: 1rem; }

.DayPicker {
  line-height: 1rem; }

.noLeftBorder {
  border-left: none; }

.sticky {
  position: sticky;
  top: 0; }

.color-green {
  color: #009200; }

.color-red {
  color: #ab3428; }

.bg-red {
  background-color: #ab3428 !important;
  color: #FFFFFF !important; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.pre {
  white-space: pre; }

pre {
  display: block;
  font-family: monospace;
  white-space: pre; }
