.header-button.left {
  float: left; }

.header-button.right {
  float: right; }

.header-button {
  line-height: 3rem;
  border-width: 0 1px 3px 1px;
  border-style: solid;
  border-color: #cfcfcf;
  margin-bottom: -3px;
  padding: 0 1rem;
  font-size: 1rem;
  background: #ededed;
  height: 3rem; }

.header-button.animated:hover {
  border-width: 2px 1px 1px 1px;
  border-top-color: #ededed; }

.header-button.active {
  border-color: #bfa948;
  background: #ccbb6e;
  color: #FFF;
  cursor: default; }

.header-button.animated.active:hover {
  border-width: 0 1px 3px 1px;
  border-top-color: #ccbb6e; }
