button, .navlink-button {
  text-align: center;
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.5rem 1.5rem;
  border-width: 0px 1px 3px 1px;
  border-color: #cfcfcf;
  border-style: solid;
  color: #888;
  background-color: #ededed;
  display: inline-block;
  cursor: pointer;
  position: relative;
  vertical-align: top; }

button.spaced, .navlink-button.spaced {
  margin-bottom: 0.5rem; }

button.block, .navlink-button.block {
  width: 100%;
  display: inline-block; }

button.small, .navlink-button.small {
  font-size: 0.6rem;
  padding: 0.25rem 0.5rem; }

button.small, .navlink-button.small {
  font-size: 0.6rem;
  padding: 0.25rem 0.5rem; }

button.big, .navlink-button.big {
  font-size: 1.2rem; }

button.nolrpadding, .navlink-button.nolrpadding {
  padding-left: 0 !important;
  padding-right: 0 !important; }

button.fill {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

button.inputHeight {
  line-height: calc(1.5rem - 1px);
  font-size: 1.2rem; }

button:focus, .navlink-button:focus {
  outline: none !important; }

button:hover, .navlink-button:hover {
  border-width: 1px 1px 2px 1px;
  border-top-color: #ededed; }

button.selected, .navlink-button.selected {
  border-width: 2px 3px 1px 3px;
  border-top-color: #ededed; }

button.primary, .navlink-button.primary {
  color: #FFF;
  background-color: #ccbb6e;
  border-color: #bfa948;
  border-top-color: #ccbb6e; }

button.primary.inverted, .navlink-button.primary.inverted {
  background-color: #ededed;
  color: #ccbb6e;
  border-color: #cfcfcf;
  border-top-color: #ededed; }

button.secondary, .navlink-button.secondary {
  background-color: #ab3428;
  border-color: #82271e;
  color: #FFFFFF;
  border-top-color: #ab3428; }

button.secondary.inverted, .navlink-button.secondary.inverted {
  background-color: #ededed;
  color: #ab3428;
  border-color: #cfcfcf;
  border-top-color: #ededed; }

button.tetriary, .navlink-button.tetriary {
  background-color: #006992;
  border-color: #00445f;
  color: #FFFFFF;
  border-top-color: #006992; }

button.tetriary.inverted, .navlink-button.tetriary.inverted {
  background-color: #ededed;
  color: #006992;
  border-color: #cfcfcf;
  border-top-color: #ededed; }

button.green, .navlink-button.green {
  background-color: #009200;
  border-color: #005f00;
  color: #FFFFFF;
  border-top-color: #009200; }

button.red, .navlink-button.red {
  background-color: #ab3428;
  border-color: #82271e;
  color: #FFFFFF;
  border-top-color: #ab3428; }

button.green.inverted, .navlink-button.green.inverted {
  background-color: #ededed;
  color: #009200;
  border-color: #cfcfcf;
  border-top-color: #ededed; }

.ButtonRightChevron {
  position: absolute;
  font-size: 200%;
  right: 0.5rem;
  top: 0.5rem; }
