header {
  margin: 0;
  font: inherit;
  vertical-align: baseline;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 4px solid #cfcfcf;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  background-color: #ededed;
  font-size: 1.2rem;
  padding: 0 1rem;
  line-height: 3rem; }
